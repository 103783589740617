import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import InformationLayout from "../../layouts/InformationLayout.jsx";
import indexData from "./index.json";
import Alert from "../../components/_ui/Alert.jsx";
import Downloads from "../../components/_ui/Downloads.jsx";
export const _frontmatter = {
  "title": "Pergola Planning - Safety Statement"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  pagesList
}) => <InformationLayout pagesList={indexData.pageIndex}>
    {children}
  </InformationLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Safety Statement`}</h1>
    <Alert type="warning" mdxType="Alert">
  <span className="text-label">Important</span>
  <p className="text-paragraph">
    Pergolas are decorative outdoor products. They are not designed or
    manufactured or intended to be used as a structural system for carrying or
    supporting ‘live’ loads such as humans or animals. They are not designed or
    manufactured to support a hanging swing or hammock or similar type object.
  </p>
    </Alert>
    <p>{`These pergolas have been designed to tolerate their own weight, suitable climbing plants, light decorative adornments (such as lanterns or low/light weight outdoor lighting systems) and moderate light weight snow or ice conditions.`}</p>
    <h2>{`Safety`}</h2>
    <p>{`Building a substantial structure like a pergola is exciting and fulfilling and working outdoors with masses of space and blue skies can create a rather pleasant atmosphere. However, it is important to fully consider and appreciate that while undertaking any such project, that you strictly adhere to all health and safety guidelines/procedures and seek professional trade advice where you are unsure or aware how to proceed.`}</p>
    <p>{`Do not become too relaxed as a casual attitude can be potentially dangerous – so, keep your mind on the job, ask for help where needed. Take a break every now and then.`}</p>
    <p><strong parentName="p">{`Please find below some helpful hints to keep your work space accident free:`}</strong></p>
    <ol>
      <li parentName="ol">{`Take your time to do the work carefully and methodically. If you are running behind schedule do not feel under pressure to rush as this can lead to accidents and a disappointing end result.`}</li>
      <li parentName="ol">{`Keep all electrical leads and connections from getting wet or damp. If it is damp or looks like rain do not use any electrical equipment.`}</li>
      <li parentName="ol">{`Unplug or disable all equipment when not in use. If you are leaving the site for any time at all, remove all the electrical equipment and work tools as these can attract attention particularly from children.`}</li>
      <li parentName="ol">{`Cover all unfilled holes and, if they are to be left for any length of time, cordon off the area to keep people and animals out with sufficient safety warning signage.`}</li>
      <li parentName="ol">{`Cover all unfilled holes and, if they are to be left for any length of time, cordon off the area to keep people and animals out with sufficient safety warning signage.`}</li>
      <li parentName="ol">{`When wood is required to be cut, remember to use a waist high work bench or saw horse. The wood must be firmly held in place by vice or clamp. This makes is not only easier to cut cleanly and accurately but helps prevent possible accidents from occurring.`}</li>
      <li parentName="ol">{`Check that all your tools are in proper working order and are in good condition.`}</li>
      <li parentName="ol">{`Do not try and do everything yourself. The main beam and ledger board, in particular, are heavy and awkwardly shaped to lift into position. It is much better to borrow another ‘set of hands’ to help.`}</li>
      <li parentName="ol">{`Ensure that any ladders you use for the assembly are sturdy and safe for the job. Extension ladders must be firmly set into the ground at their base and should be at the required secure safe angle.`}</li>
      <li parentName="ol">{`Keep the site clear and tidy at all times and ensure there are no hazardous or flammable materials present. Do not leave any tools, leads wires cables etc lying around as, again, these are potential hazards which could lead to accidents and injury.`}</li>
      <li parentName="ol">{`Purchase proper safety equipment meeting required safety standards and use it. Comply with all safety recommendations provided with the equipment you use.`}</li>
      <li parentName="ol">{`Wear safety glasses at all times, protective clothing where necessary, protective footwear and ear protectors when using tools or powered equipment.`}</li>
    </ol>
    <p>{`The above helpful hints are provided to assist you with the enjoyment of building your pergola. These are provided on the strict understanding that you seek expert professional guidance and assistance where required. Perfect Pergolas Limited will not accept any responsibility or liability for any loss or damage suffered or caused to you or to a third party or to any property.`}</p>
    <h2>{`Downloads & Support`}</h2>
    <Downloads files={[{
      filename: "Pergola assembly guide",
      path: "/downloads/ASSEMBLY.pdf"
    }, {
      filename: "Pergola wood treatment information",
      path: "/downloads/EN-Wolmanit_CX_enduser_022010.pdf"
    }]} mdxType="Downloads" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      